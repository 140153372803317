// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

	MobileMenu: {
		init: function() {
            FE.MobileMenu.indicateSubMenu();
			$('.nav-mobile-icon').click(function() {
                $('.nav-mobile').slideToggle();
            });
		},
        indicateSubMenu: function() {
            $('.nav-mobile > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                });
            });
        }
	},

    headerSlider: {
        init: function() {
            // fix position of mask
            $('.header-mask').css('position', 'relative');
            var sliderSettings = {
                elem: 'figure',
                fadeSpeed: 1000,
                rotationSpeed: 5000,
                statusBar: false,
                controls: false
            };
            $('.header-image .ce-hero-slider-slides').objectSlider(sliderSettings);
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		FE.MobileMenu.init();
        if ( $('.ce-hero-slider-slides figure').length ) {
            FE.headerSlider.init();
        }
	});

}(jQuery, window, document));